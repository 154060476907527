<template>
  <div class="page-content" v-if="dailyQuest">
    <b-row>
      <b-col md="10">
        <page-breadcrumb
          title="Daily Mission"
          class="mb-3"
          :items="breadcrumbItems"
        />
      </b-col>
    </b-row>
    <section class="my-3">
      <h2 class="h2">Daily Mission</h2>
      <b-card class="mt-1">
        <b-row>
          <b-col cols="12">
            <b-form-group
              label="Mission Name"
              label-for="h-mission-name"
              label-cols-md="3"
            >
              <ui-component :component-data="dailyQuest.name" class="border" />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Mission Description"
              label-for="h-mission-description"
              label-cols-md="3"
            >
              <ui-component
                :component-data="dailyQuest.description"
                class="border"
              />
            </b-form-group>
          </b-col>
          <!-- <b-col cols="12">
            <b-form-group
              label="Category"
              label-for="h-category"
              label-cols-md="3"
            >
              <b-form-select v-model="dailyQuest.category" :options="categories" />
            </b-form-group>
          </b-col> -->
        </b-row>
        <b-row v-for="(item, index) in dailyQuest.rewards" :key="index">
          <b-col cols="12">
            <b-form-group label="Reward" label-for="h-reward" label-cols-md="3">
              <b-form-select v-model="item.type" :options="select_rewards" />
            </b-form-group>
            <template v-if="item.type == 'SMOOTHY'">
              <b-form-group label="Variant" label-for="h-variant" label-cols-md="3">
                <b-form-select v-model="item.variant" :options="variant_options" />
              </b-form-group>
              <b-form-group label="Tier" label-for="h-tier" label-cols-md="3">
                <b-form-select v-model="item.tier" :options="tier_options" />
              </b-form-group>
            </template>
          </b-col>
          <b-col cols="12">
            <b-form-group label="Number" label-for="h-number" label-cols-md="3">
              <b-form-input
                v-model="item.amount"
                id="h-number"
                placeholder="Number"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-card>
    </section>

    <action-buttons
      update-button
      back-button
      @update="update"
      :is-updating="isUpdating"
      :is-deleting="isDeleting"
    />
  </div>
</template>

<script>
import service from "../service";
import pronunLessonService from "@/modules/pronun/service";
export default {
  data() {
    return {
      isUpdating: false,
      isDeleting: false,
      dailyQuest: null,
      lessons: [],
      categories: [
        {
          text: "Pronun",
          value: "pronun",
        },
      ],
      select_rewards: [
        {
          text: "Smoothies",
          value: "SMOOTHY",
        },
        {
          text: "Token",
          value: "TOKEN",
        },
      ],
      variant_options:[
        {
          text: "Red",
          value: 1,
        },
        {
          text: "Yellow",
          value: 2,
        },
        {
          text: "Green",
          value: 3,
        },
      ],
      tier_options:[
        {
          text:"Mini-size",
          value: 1
        },
        {
          text:"Medium size",
          value: 2
        },
        {
          text:"Large size",
          value: 3
        },
        {
          text:"Extra large size",
          value: 4
        },
      ]
    };
  },
  computed: {
    dailyQuestId() {
      return this.$route.params.daily_id;
    },
    breadcrumbItems() {
      let items = [
        {
          text: "Daily Mission List",
          to: { name: "quest-daily-list" },
        },
      ];
      items.push({ text: "Daily Mission Detail", active: true });
      return items;
    },
  },
  watch: {
    "daily.category": function (newValue) {
      if (newValue) {
        this.getLessions();
      }
    },
  },
  async created() {
    this.getDailyQuest();
  },
  methods: {
    async getLessions() {
      let response = await pronunLessonService.getAll();
      if (response) {
        this.lessons = response.list.map((item) => {
          return {
            value: item._id,
            text: item.title,
          };
        });
      }
    },
    async getDailyQuest() {
      this.dailyQuest = await service.get({ id: this.dailyQuestId });
    },
    async update() {
      this.isUpdating = true;
      await service.update({
        data: JSON.stringify(this.dailyQuest),
      });
      this.isUpdating = false;
    },
  },
};
</script>
